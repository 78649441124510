<template>
  <div>
    <Loader v-if="isLoading" />
    <div v-else>
      <h3 style="margin-bottom: 25px">{{ $l.get("plan-edit.header") }}</h3>
      <PlanForm
        :plan="data.plan"
        :onSubmit="onSubmit"
        v-if="data && data.plan"
      />
    </div>
  </div>
</template>

<script>
import SubmitFormInteractor from "@/business/plan-edit/submit-form";
import InitPlanEditInteractor from "@/business/plan-edit/init-plan-edit";
import PlanEditScreenController from "@/adapters/controllers/screen-plan-edit";
import PlanForm from "@/application/components/plans/PlanForm";
import Loader from "@/application/components/Loader";

export default {
  screen_name: "plan-edit",
  components: { Loader, PlanForm },
  data() {
    return {
      controller: null,
      interactors: {
        submitForm: null,
        initPlanEdit: null
      },
      data: null,
      isLoading: false
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(PlanEditScreenController);

    //{ INTERACTORS
    this.interactors.initPlanEdit = this.$injector.get(InitPlanEditInteractor);
    this.interactors.submitForm = this.$injector.get(SubmitFormInteractor);
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    const id = this.$route.params.id;
    this.interactors.initPlanEdit.handle(id);
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    onSubmit() {
      this.interactors.submitForm.handle(this.data.plan);
    }
  }
};
</script>

<style lang="scss"></style>
